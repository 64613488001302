import { LitElement, html, css } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
import convertToCustomProperty from '../../utils/convert-to-custom-property';
import spacings from '../../utils/spacings';

class CampText extends LitElement {
  static get properties() {
    return {
      as: { type: String },
      appearance: { type: String },
      color: { type: String },
      height: { type: String },
      family: { type: String },
      size: { type: String },
      weight: { type: String },
      transform: { type: String },
      ...spacings.properties
    };
  }

  render() {
    switch (this.as) {
      case 'cite':
        return html`
          <cite class="${this._classes}" style="${this._styles}"><slot></slot></cite>
        `;
      case 'div':
        return html`
          <div class="${this._classes}" style="${this._styles}">
            <slot></slot>
          </div>
        `;
      case 'h1':
        return html`
          <h1 class="${this._classes}" style="${this._styles}">
            <slot></slot>
          </h1>
        `;
      case 'h2':
        return html`
          <h2 class="${this._classes}" style="${this._styles}">
            <slot></slot>
          </h2>
        `;
      case 'h3':
        return html`
          <h3 class="${this._classes}" style="${this._styles}">
            <slot></slot>
          </h3>
        `;
      case 'h4':
        return html`
          <h4 class="${this._classes}" style="${this._styles}">
            <slot></slot>
          </h4>
        `;
      case 'h5':
        return html`
          <h5 class="${this._classes}" style="${this._styles}">
            <slot></slot>
          </h5>
        `;
      case 'h6':
        return html`
          <h6 class="${this._classes}" style="${this._styles}">
            <slot></slot>
          </h6>
        `;
      case 'p':
        return html`
          <p class="${this._classes}" style="${this._styles}"><slot></slot></p>
        `;
      default:
        return html`
          <span class="${this._classes}" style="${this._styles}"><slot></slot></span>
        `;
    }
  }

  get _classes() {
    return classMap({
      'camp-Text': true,
      'camp-Text--body1': this.appearance === 'body1',
      'camp-Text--body2': this.appearance === 'body2',
      'camp-Text--body3': this.appearance === 'body3',
      'camp-Text--heading1': this.appearance === 'heading1',
      'camp-Text--heading2': this.appearance === 'heading2',
      'camp-Text--heading3': this.appearance === 'heading3',
      'camp-Text--heading4': this.appearance === 'heading4',
      'camp-Text--heading5': this.appearance === 'heading5'
    });
  }

  get _styles() {
    let styles = {};
    if (this.color) {
      styles.color = convertToCustomProperty(this.color);
      styles.fill = convertToCustomProperty(this.color);
    }
    if (this.height) {
      styles.lineHeight = convertToCustomProperty(this.height);
    }
    if (this.family) {
      styles.fontFamily = convertToCustomProperty(this.family);
    }
    if (this.size) {
      styles.fontSize = convertToCustomProperty(this.size);
    }
    if (this.weight) {
      styles.fontWeight = convertToCustomProperty(this.weight);
    }
    if (this.transform) {
      styles.textTransform = this.transform;
    }
    return styleMap({
      ...styles,
      ...spacings.map(this)
    });
  }

  static get styles() {
    return css`
      :host {
        box-sizing: border-box;
        color: inherit;
        display: inline-flex;
        fill: inherit;
        font-family: var(
          --ff-standard,
          'IBM Plex Sans',
          'Franklin Gothic',
          'ITC Franklin Gothic',
          helvetica,
          arial,
          sans-serif
        );
        text-decoration: none;
        text-transform: none;
      }

      .camp-Text {
        margin: 0;
        padding: 0;
      }

      .camp-Text--body1 {
        line-height: var(--lh-100, 16px);
        font-size: var(--fs-100, 12px);
        font-weight: var(--fw-regular, 400);
      }

      .camp-Text--body2 {
        line-height: var(--lh-200, 20px);
        font-size: var(--fs-200, 14px);
        font-weight: var(--fw-regular, 400);
      }

      .camp-Text--body3 {
        line-height: var(--lh-300, 24px);
        font-size: var(--fs-300, 16px);
        font-weight: var(--fw-regular, 400);
      }

      .camp-Text--heading1 {
        line-height: var(--lh-200, 20px);
        font-size: var(--fs-200, 14px);
        font-weight: var(--fw-semi-bold, 600);
      }

      .camp-Text--heading2 {
        line-height: var(--lh-300, 24px);
        font-size: var(--fs-300, 16px);
        font-weight: var(--fw-semi-bold, 600);
      }

      .camp-Text--heading3 {
        line-height: var(--lh-400, 28px);
        font-size: var(--fs-500, 20px);
        font-weight: var(--fw-semi-bold, 600);
      }

      .camp-Text--heading4 {
        line-height: var(--lh-500, 32px);
        font-size: var(--fs-600, 24px);
        font-weight: var(--fw-semi-bold, 600);
      }

      .camp-Text--heading5 {
        line-height: var(--lh-700, 44px);
        font-size: var(--fs-800, 32px);
        font-weight: var(--fw-regular, 400);
      }
    `;
  }
}

customElements.define('camp-text', CampText);
