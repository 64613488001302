import convertToCustomProperty from './convert-to-custom-property';

const properties = {
  m: { type: String },
  mt: { type: String },
  mr: { type: String },
  mb: { type: String },
  ml: { type: String },
  mx: { type: String },
  my: { type: String },
  p: { type: String },
  pt: { type: String },
  pr: { type: String },
  pb: { type: String },
  pl: { type: String },
  px: { type: String },
  py: { type: String }
};

function map(attributes) {
  const styles = {};
  if (attributes.m) {
    styles.margin = convertToCustomProperty(attributes.m);
  }
  if (attributes.mx) {
    const mx = convertToCustomProperty(attributes.mx);
    styles.marginRight = mx;
    styles.marginLeft = mx;
  }
  if (attributes.my) {
    const my = convertToCustomProperty(attributes.my);
    styles.marginTop = my;
    styles.marginBottom = my;
  }
  if (attributes.mt) {
    styles.marginTop = convertToCustomProperty(attributes.mt);
  }
  if (attributes.mr) {
    styles.marginRight = convertToCustomProperty(attributes.mr);
  }
  if (attributes.mb) {
    styles.marginBottom = convertToCustomProperty(attributes.mb);
  }
  if (attributes.ml) {
    styles.marginLeft = convertToCustomProperty(attributes.ml);
  }
  if (attributes.p) {
    styles.padding = convertToCustomProperty(attributes.p);
  }
  if (attributes.px) {
    const px = convertToCustomProperty(attributes.px);
    styles.paddingRight = px;
    styles.paddingLeft = px;
  }
  if (attributes.py) {
    const py = convertToCustomProperty(attributes.py);
    styles.paddingTop = py;
    styles.paddingBottom = py;
  }
  if (attributes.pt) {
    styles.paddingTop = convertToCustomProperty(attributes.pt);
  }
  if (attributes.pr) {
    styles.paddingRight = convertToCustomProperty(attributes.pr);
  }
  if (attributes.pb) {
    styles.paddingBottom = convertToCustomProperty(attributes.pb);
  }
  if (attributes.pl) {
    styles.paddingLeft = convertToCustomProperty(attributes.pl);
  }
  return styles;
}

export default {
  properties,
  map
};
