import { LitElement, html, css } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
import spacings from '../../utils/spacings';

class CampFlex extends LitElement {
  static get properties() {
    return {
      alignItems: { type: String, attribute: 'align-items' },
      alignContent: { type: String, attribute: 'align-content' },
      direction: { type: String },
      justifyContent: { type: String, attribute: 'justify-content' },
      wrap: { type: String },
      order: { type: String },
      alignSelf: { type: String, attribute: 'align-self' },
      justifySelf: { type: String, attribute: 'justify-self' },
      grow: { type: String },
      shrink: { type: String },
      basis: { type: String },
      variant: { type: String },
      ...spacings.properties
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <div class="${this._classes}" style="${this._styles}">
        <slot></slot>
      </div>
    `;
  }

  get _classes() {
    return classMap({
      'camp-Flex': true,
      'as-inline': this.variant === 'inline',
      'as-item': this.variant === 'item'
    });
  }

  get _styles() {
    let styles = {};
    if (this.direction) {
      styles.flexDirection = this.direction;
    }
    if (this.alignItems) {
      styles.alignItems = this.alignItems;
    }
    if (this.alignContent) {
      styles.alignContent = this.alignContent;
    }
    if (this.justifyContent) {
      styles.justifyContent = this.justifyContent;
    }
    if (this.wrap) {
      styles.flexWrap = this.wrap;
    }
    if (this.order) {
      styles.order = this.order;
    }
    if (this.alignSelf) {
      styles.alignSelf = this.alignSelf;
    }
    if (this.justifySelf) {
      styles.justifySelf = this.justifySelf;
    }
    if (this.grow) {
      styles.flexGrow = this.grow;
    }
    if (this.shrink) {
      styles.flexShrink = this.shrink;
    }
    if (this.basis) {
      styles.flexBasis = this.basis;
    }
    return styleMap({
      ...styles,
      ...spacings.map(this)
    });
  }

  static get styles() {
    return css`
      :host {
        display: contents;
      }

      .camp-Flex {
        display: flex;
      }

      .as-inline {
        display: flex-inline;
      }

      .as-item {
        display: block;
      }
    `;
  }
}

customElements.define('camp-flex', CampFlex);
