import { LitElement, html, css } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { ifDefined } from 'lit-html/directives/if-defined';
import { styleMap } from 'lit-html/directives/style-map';
import spacings from '../../utils/spacings';

class CampCard extends LitElement {
  static get properties() {
    return {
      actionable: { type: Boolean, reflect: true },
      href: { type: String },
      target: { type: String },
      title: { type: String },
      ...spacings.properties,
    };
  }

  constructor() {
    super();
    this.actionable = false;
  }

  createRenderRoot() {
    return this.attachShadow({
      mode: 'open',
    });
  }

  render() {
    if (this.actionable) {
      return this.href ? this._anchor : this._button;
    }
    return this._box;
  }

  get _anchor() {
    return html`
      <a
        class="${this._classes}"
        href="${this.href}"
        target="${ifDefined(this.target)}"
        title="${ifDefined(this.title)}"
        style="${this._styles}"
      >
        <slot></slot>
      </a>
    `;
  }

  get _button() {
    return html`
      <button class="${this._classes}" type="button" style="${this._styles}">
        <slot></slot>
      </button>
    `;
  }

  get _box() {
    return html`
      <div class="${this._classes}" style="${this._styles}">
        <slot></slot>
      </div>
    `;
  }

  get _classes() {
    return classMap({
      'camp-Card': true,
      'camp-Card--actionable': this.actionable,
    });
  }

  get _styles() {
    return styleMap(spacings.map(this));
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .camp-Card {
        background-color: var(--white, #fff);
        border-color: var(--camp-Card-borderColor, var(--slate-200, #ced3e0));
        border-radius: var(--radii-200, 8px);
        border-style: solid;
        border-width: var(--b-100, 1px);
        box-sizing: border-box;
        color: inherit;
        display: block;
        height: inherit;
        margin: 0px;
        padding: 0px;
        text-decoration: none;
        text-transform: none;
        width: 100%;
      }

      .camp-Card--actionable {
        cursor: pointer;
      }

      .camp-Card--actionable:hover,
      .camp-Card--actionable:focus {
        box-shadow: var(--shadow-200, 0 4px 12px 0 rgba(31, 33, 41, 0.08));
      }
    `;
  }
}

customElements.define('camp-card', CampCard);
