import { LitElement, html, css } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
import { ifDefined } from 'lit-html/directives/if-defined';
import spacings from '../../utils/spacings';

class CampButton extends LitElement {
  static get properties() {
    return {
      appearance: { type: String },
      disabled: { type: Boolean, reflect: true },
      size: { type: String },
      variant: { type: String },
      download: { type: String },
      href: { type: String },
      name: { type: String },
      target: { type: String },
      title: { type: String },
      type: { type: String },
      ...spacings.properties,
    };
  }

  constructor() {
    super();
    this.appearance = 'primary';
    this.disabled = false;
    this.size = 'medium';
    this.variant = 'fill';
    this._setDisabledEventListeners();
  }

  createRenderRoot() {
    return this.attachShadow({
      mode: 'open',
    });
  }

  render() {
    const hasReference = this.href && !this.disabled;
    return html` ${hasReference ? this._anchor : this._button} `;
  }

  _dispatchSubmitEvent() {
    const action = document.createElement('input');
    action.setAttribute('hidden', true);
    action.setAttribute('type', 'submit');
    this.appendChild(action);
    action.click();
    this.removeChild(action);
  }

  _setDisabledEventListeners() {
    const handle = (event) => {
      if (this.disabled) {
        event.stopImmediatePropagation();
        return false;
      }
      if (this.type === 'submit' && event.type === 'click' && event.target === this) {
        this._dispatchSubmitEvent();
      }
    };
    this.addEventListener('click', handle, true);
    this.addEventListener('touchstart', handle, true);
    this.addEventListener('touchend', handle, true);
    this.addEventListener('keydown', handle, true);
  }

  get _anchor() {
    return html`
      <a
        class="${this._classes}"
        download="${ifDefined(this.download)}"
        href="${this.href}"
        name="${ifDefined(this.name)}"
        style="${this._styles}"
        target="${ifDefined(this.target)}"
        title="${ifDefined(this.title)}"
        type="${ifDefined(this.type)}"
      >
        <slot></slot>
      </a>
    `;
  }

  get _button() {
    return html`
      <button
        class="${this._classes}"
        name="${ifDefined(this.name)}"
        style="${this._styles}"
        type="${ifDefined(this.type)}"
        ?disabled="${this.disabled}"
      >
        <slot></slot>
      </button>
    `;
  }

  get _classes() {
    return classMap({
      'camp-Button': true,
      'camp-Button--destructive': this.appearance === 'destructive',
      'camp-Button--small': this.size === 'small',
      'as-fill': this.variant === 'fill',
      'as-outline': this.variant === 'outline',
      'as-text': this.variant === 'text',
    });
  }

  get _styles() {
    return styleMap(spacings.map(this));
  }

  static get styles() {
    return css`
      :host {
        display: inline-flex;
      }

      :host([disabled]) {
        cursor: not-allowed;
      }

      .camp-Button {
        border-radius: var(--radii-100, 4px);
        border-style: solid;
        border-width: var(--b-100, 1px);
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: inherit;
        margin: 0px;
        overflow: visible;
        padding: var(--sp-300, 8px) var(--sp-400, 12px) !important;
        font-family: var(
          --ff-standard,
          'IBM Plex Sans',
          'Franklin Gothic',
          'ITC Franklin Gothic',
          helvetica,
          arial,
          sans-serif
        );
        font-size: var(--fs-200, 14px);
        font-weight: var(--fw-medium, 500);
        line-height: var(--lh-200, 20px);
        text-decoration: none;
        text-transform: none;
        transition-duration: var(--duration-medium, 200ms);
        transition-property: background, border;
        transition-timing-function: var(--transition-out, cubic-bezier(0.23, 1, 0.32, 1));
        width: 100%;
      }

      :host([disabled]) .camp-Button {
        pointer-events: none;
      }

      .camp-Button--small {
        padding: var(--sp-200, 4px) var(--sp-300, 8px) !important;
      }

      .camp-Button.as-fill {
        background-color: var(--camp-Button-backgroundColor, var(--ocean-500, #356ae6));
        border-color: var(--camp-Button-borderColor, var(--ocean-500, #356ae6));
        color: var(--camp-Button-color, var(--white, #fff));
        fill: var(--camp-Button-color, var(--white, #fff));
      }

      .camp-Button.as-fill:hover,
      .camp-Button.as-fill:focus {
        background-color: var(--camp-Button-onFocus-backgroundColor, var(--ocean-600, #1b54d9));
        border-color: var(--camp-Button-onFocus-borderColor, var(--ocean-600, #1b54d9));
        color: var(--camp-Button-primary-foreground--focused, var(--white, #fff));
        fill: var(--camp-Button-onFocus-color, var(--white, #fff));
      }

      .camp-Button.as-fill:active {
        background-color: var(--camp-Button-onActive-backgroundColor, var(--ocean-700, #123b99));
        border-color: var(--camp-Button-onActive-borderColor, var(--ocean-700, #123b99));
        color: var(--camp-Button-primary-onActive-color, var(--white, #fff));
        fill: var(--camp-Button-primary-onActive-fill, var(--white, #fff));
      }

      :host([disabled]) .camp-Button.as-fill {
        background-color: var(--camp-Button-onDisabled-backgroundColor, var(--ocean-400, #5884ea));
        border-color: var(--camp-Button-onDisabled-borderColor, var(--ocean-300, #c1d1f7));
        color: var(--camp-Button-onDisabled-color, var(--white, #fff));
        fill: var(--camp-Button-onDisabled-color, var(--white, #fff));
      }

      .camp-Button--destructive.as-fill {
        background-color: var(
          --camp-Button--destructive-backgroundColor,
          var(--strawberry-500, #f3376b)
        );
        border-color: var(--camp-Button--destructive-borderColor, var(--strawberry-500, #f3376b));
        color: var(--camp-Button--destructive-color, var(--white, #fff));
        fill: var(--camp-Button--destructive-color, var(--white, #fff));
      }

      .camp-Button--destructive.as-fill:hover,
      .camp-Button--destructive.as-fill:focus {
        background-color: var(
          --camp-Button--destructive-onFocus-backgroundColor,
          var(--strawberry-600, #e40e49)
        );
        border-color: var(
          --camp-Button--destructive-onFocus-borderColor,
          var(--strawberry-600, #e40e49)
        );
        color: var(--camp-Button--destructive-primary-foreground--focused, var(--white, #fff));
        fill: var(--camp-Button--destructive-onFocus-color, var(--white, #fff));
      }

      .camp-Button--destructive.as-fill:active {
        background-color: var(
          --camp-Button--destructive-onActive-backgroundColor,
          var(--strawberry-700, #c60d40)
        );
        border-color: var(
          --camp-Button--destructive-onActive-borderColor,
          var(--strawberry-700, #c60d40)
        );
      }

      :host([disabled]) .camp-Button--destructive.as-fill {
        background-color: var(
          --camp-Button--destructive-onDisabled-backgroundColor,
          var(--strawberry-400, #f56e94)
        );
        border-color: var(
          --camp-Button--destructive-onDisabled-borderColor,
          var(--strawberry-400, #f56e94)
        );
        color: var(--camp-Button--destructive-onDisabled-color, var(--white, #fff));
        fill: var(--camp-Button--destructive-onDisabled-color, var(--white, #fff));
      }

      .camp-Button.as-outline {
        background-color: var(--camp-Button-asOutline-backgroundColor, var(--white, #fff));
        border-color: var(--camp-Button-asOutline-borderColor, var(--slate-200, #ced3e0));
        color: var(--camp-Button-asOutline-color, var(--slate-600, #1f2129));
        fill: var(--camp-Button-asOutline-color, var(--slate-600, #1f2129));
      }

      .camp-Button.as-outline:hover,
      .camp-Button.as-outline:focus {
        background-color: var(
          --camp-Button-asOutline-onFocus-backgroundColor,
          var(--slate-150, #edf0f7)
        );
        border-color: var(--camp-Button-asOutline-onFocus-borderColor, var(--slate-200, #ced3e0));
        color: var(--camp-Button-asOutline-onFocus-color, var(--slate-600, #1f2129));
        fill: var(--camp-Button-asOutline-onFocus-color, var(--slate-600, #1f2129));
        outline: none;
      }

      .camp-Button.as-outline:active {
        background-color: var(
          --camp-Button-asOutline-onActive-backgroundColor,
          var(--slate-200, #ced3e0)
        );
        border-color: var(--camp-Button-asOutline-onActive-borderColor, var(--slate-200, #ced3e0));
        color: var(--camp-Button-asOutline-onActive-color, var(--slate-600, #1f2129));
        fill: var(--camp-Button-asOutline-onActive-color, var(--slate-600, #1f2129));
      }

      :host([disabled]) .camp-Button.as-outline {
        background-color: var(
          --camp-Button-asOutline-onDisabled-backgroundColor,
          var(--white, #fff)
        );
        border-color: var(
          --camp-Button-asOutline-onDisabled-borderColor,
          var(--slate-200, #ced3e0)
        );
        color: var(--camp-Button-asOutline-onDisabled-color, var(--slate-400, #5f667e));
        fill: var(--camp-Button-asOutline-onDisabled-color, var(--slate-400, #5f667e));
      }

      .camp-Button.as-text {
        background-color: var(
          --camp-Button-asText-backgroundColor,
          var(--transparent, transparent)
        );
        border-color: var(--camp-Button-asText-borderColor, var(--transparent, transparent));
        color: var(--camp-Button-asText-color, var(--ocean-500, #356ae6));
        fill: var(--camp-Button-asText-color, var(--ocean-500, #356ae6));
      }

      .camp-Button.as-text:hover,
      .camp-Button.as-text:focus {
        background-color: var(
          --camp-Button-asText-onFocus-backgroundColor,
          var(--ocean-150, #edf3fe)
        );
        border-color: var(--camp-Button-asText-onFocus-borderColor, var(--ocean-150, #edf3fe));
        color: var(--camp-Button-asText-onFocus-color, var(--ocean-500, #356ae6));
        fill: var(--camp-Button-asText-onFocus-color, var(--ocean-500, #356ae6));
        outline: none;
      }

      .camp-Button.as-text:active {
        background-color: var(
          --camp-Button-asText-onActive-backgroundColor,
          var(--ocean-200, #e3ebfc)
        );
        border-color: var(--camp-Button-asText-onActive-borderColor, var(--ocean-200, #e3ebfc));
        color: var(--camp-Button-asText-onActive-color, var(--ocean-500, #356ae6));
        fill: var(--camp-Button-asText-onActive-color, var(--ocean-500, #356ae6));
      }

      :host([disabled]) .camp-Button.as-text {
        background-color: var(
          --camp-Button-asText-onDisabled-backgroundColor,
          var(--transparent, transparent)
        );
        border-color: var(
          --camp-Button-asText-onDisabled-borderColor,
          var(--transparent, transparent)
        );
        color: var(--camp-Button-asText-onDisabled-color, var(--ocean-400, #5884ea));
        fill: var(--camp-Button-asText-onDisabled-color, var(--ocean-400, #5884ea));
      }

      .camp-Button--destructive.as-text {
        background-color: var(
          --camp-Button-asText--destructive-backgroundColor,
          var(--transparent, transparent)
        );
        border-color: var(
          --camp-Button-asText--destructive-borderColor,
          var(--transparent, transparent)
        );
        color: var(--camp-Button-asText--destructive-color, var(--strawberry-600, #e40e49));
        fill: var(--camp-Button-asText--destructive-color, var(--strawberry-600, #e40e49));
      }

      .camp-Button--destructive.as-text:hover,
      .camp-Button--destructive.as-text:focus {
        background-color: var(
          --camp-Button-asText-onFocus--destructive-backgroundColor,
          var(--strawberry-100, #fff2f6)
        );
        border-color: var(
          --camp-Button-asText-onFocus--destructive-borderColor,
          var(--strawberry-100, #fff2f6)
        );
        color: var(--camp-Button-asText-onFocus--destructive-color, var(--strawberry-600, #e40e49));
        fill: var(--camp-Button-asText-onFocus--destructive-color, var(--strawberry-600, #e40e49));
      }

      .camp-Button--destructive.as-text:active {
        background-color: var(
          --camp-Button-asText-onActive--destructive-backgroundColor,
          var(--strawberry-200, #fcdee7)
        );
        border-color: var(
          --camp-Button-asText-onActive--destructive-borderColor,
          var(--strawberry-200, #fcdee7)
        );
        color: var(
          --camp-Button-asText-onActive--destructive-color,
          var(--strawberry-600, #e40e49)
        );
        fill: var(--camp-Button-asText-onActive--destructive-color, var(--strawberry-600, #e40e49));
      }

      :host([disabled]) .camp-Button--destructive.as-text {
        background-color: var(
          --camp-Button-asText-onDisabled--destructive-backgroundColor,
          var(--transparent, transparent)
        );
        border-color: var(
          --camp-Button-asText-onDisabled--destructive-borderColor,
          var(--transparent, transparent)
        );
        color: var(
          --camp-Button-asText-onDisabled--destructive-color,
          var(--strawberry-400, #f56e94)
        );
        fill: var(
          --camp-Button-asText-onDisabled--destructive-color,
          var(--strawberry-400, #f56e94)
        );
      }
    `;
  }
}

customElements.define('camp-button', CampButton);
